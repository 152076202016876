<template>
  <div class="content">
    <van-nav-bar title="歷史評估記錄" left-arrow @click-left="$router.back()" class="van-nav-bar-title" />
    <div class="result-content">
      <div class="user-info">
        <div class="user-info-header">
          <div>
            <div>個人基本信息</div>
          </div>
          <div>
            <div>生成時間：{{ userData.created_at || "--" }}</div>
          </div>
        </div>
        <div class="user-info-content">
          <div class="user-info-age-sex-height">
            <div>
              <div class="basic-info-label">年齡</div>
              <div class="basic-info-value">
                <p>{{ userData.age || "--" }}<span class="unit">岁</span></p>
              </div>
            </div>
            <div>
              <div class="basic-info-label">性別</div>
              <div class="basic-info-value">{{ userData.sex == 1 ? "男" : "女" }}</div>
            </div>
            <div>
              <div class="basic-info-label">身高</div>
              <div class="basic-info-value">
                <p>{{ userData.height || "--" }}<span>cm</span></p>
              </div>
            </div>
          </div>
          <div class="user-info-weight">
            <div class="basic-info-label">体重</div>
            <div class="basic-info-value">
              <p>{{ userData.weight || "--" }}<span>kg</span></p>
            </div>
          </div>
          <div class="user-info-waist">
            <div class="basic-info-label">腰围</div>
            <div class="basic-info-value">
              <p>{{ userData.waist || "--" }}<span>cm</span></p>
            </div>
          </div>
          <div class="user-info-hip">
            <div class="basic-info-label">臀围</div>
            <div class="basic-info-value">
              <p>{{ userData.hip || "--" }}<span>cm</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="user-exam-info">
        <div class="info-title">生化結果信息</div>
        <div class="info-detail">
          <div class="detail-table-tr detail-table-header">
            <div>主要檢查項目</div>
            <div>檢查結果</div>
            <div>與臨床標準比較</div>
            <div>與同類族群比較</div>
          </div>
          <div v-for="item in bioTableList" :key="item.id">
            <div class="detail-table-type">{{ item.name }}</div>
            <div class="detail-table-tr detail-table-td"
              :class="index % 2 === 0 ? 'detail-table-even' : 'detail-table-odd'" v-for="(bio, index) in item.children"
              :key="bio.key">
              <div>{{ getExamine(userData.examine, bio.key).item_d || "--" }}</div>
              <div class="font-color-red" v-if="getExamine(userData.examine, bio.key).cmp_n === '01'">
                {{ getExamine(userData.examine, bio.key).value || "--" }}
                {{ getExamine(userData.examine, bio.key).unit || "--" }}
              </div>
              <div v-else>
                {{ getExamine(userData.examine, bio.key).value || "--" }}
                {{ getExamine(userData.examine, bio.key).unit || "--" }}
              </div>
              <div class="font-color-red" v-if="getExamine(userData.examine, bio.key).cmp_n === '01'">
                {{ getExamine(userData.examine, bio.key).group_n || "--" }}
              </div>
              <div v-else>{{ getExamine(userData.examine, 'SBP').group_n || "--" }}</div>
              <div v-if="getExamine(userData.examine, bio.key).cmp_p === '01'">
                <div class="detail-bio-down font-color-red">{{
                  getExamine(userData.examine,
                    bio.key).group_p || "--" }}</div>
              </div>
              <div v-else-if="getExamine(userData.examine, bio.key).cmp_p === '05'">
                <div class="detail-bio-up font-color-red">{{
                  getExamine(userData.examine,
                    bio.key).group_p || "--" }}</div>
              </div>
              <div v-else-if="getExamine(userData.examine, bio.key).cmp_p === '08'">
                <div class="detail-bio-good font-color-green">{{
                  getExamine(userData.examine,
                    bio.key).group_p || "--" }}</div>
              </div>
              <div v-else>{{ getExamine(userData.examine,
                bio.key).group_p || "--" }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="user-disease-info">
        <div class="info-title">疾病風險</div>
        <div class="user-disease-content">
          <div v-for="dis in disPackages" :key="dis.id" :class="dis.isBuy ? 'item-unlock' : 'item-lock'"
            class="user-disease-item" @click="buyExamine(dis)">
            <div class="disease-item-label">{{ dis.name }}</div>
            <img v-if="dis.isBuy" src="../../assets/images/myLink/result/arrow.png" alt="" />
            <img v-else src="../../assets/images/myLink/result/lock.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-confirm" v-if="isBuy" @click.self="isBuy = false">
      <div class="dialog-confirm-content">
        <img src="../../assets/images/myLink/result/close.png" @click="isBuy = false" class="dialog-confirm-close"
          alt="close">
        <div class="dialog-confirm-header">溫馨提示</div>
        <div class="dialog-confirm-body">確認使用當前資料評估癌症風險<br>（需要支付900積分）</div>
        <div class="dialog-confirm-footer">
          <div class="dialog-confirm-btn" @click="confirmBuy">確認</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listChart from '@/components/custom/listChart'
import diseaseChart from '@/components/custom/diseaseChart'
import disChart from './charts/disChart.vue'
import Vue from 'vue'

import { detailMyLink } from '@/js/axios.js'

import { disRiskRanges } from './data'

import { Base64 } from 'js-base64'

import {
  NavBar,
  Progress,
  Swipe,
  SwipeItem,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Field,
  Picker,
  Popup,
  Area,
  DatetimePicker,
  Cell,
  CellGroup,
  Loading,
  NumberKeyboard,
} from 'vant'

Vue.use(NavBar)
  .use(Progress)
  .use(Swipe)
  .use(SwipeItem)
  .use(RadioGroup)
  .use(Radio)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Field)
  .use(Picker)
  .use(Popup)
  .use(Area)
  .use(DatetimePicker)
  .use(Cell)
  .use(CellGroup)
  .use(Loading)
  .use(NumberKeyboard)

export default {
  name: 'myLinkResult',
  components: {
    'list-chart': listChart,
    'disease-chart': diseaseChart,
    "dis-chart": disChart
  },
  data () {
    return {
      userData: {
        examine: []
      },
      diseaseType: 'gxy',
      id: '',
      disListData1: [],
      disListData2: [],
      disListData3: [],
      listRate1: [
      ],
      listRate2: [
      ],
      disList1: ["A01", "A02", "A16", "A05"],
      disList2: ["A07"],
      disList3: ["A14", "A12", "A10", "A03", "A15", "A04", "A11", "A07", "A13", "A08", "A09", "A06", "A17"],
      bioTableList: [
        {
          id: 2,
          name: "血糖",
          children: [
            {
              name: "空腹血糖",
              key: "FG"
            },
            {
              name: "糖化血红蛋白",
              key: "HBA1C"
            },
          ],
        },
        {
          id: 3,
          name: "血脂肪",
          children: [
            {
              name: "总胆固醇",
              key: "CHOL"
            },
            {
              name: "低密度脂蛋白",
              key: "LDLC"
            },
            {
              name: "高密度脂蛋白",
              key: "HDLC"
            },
            {
              name: "甘油三酯",
              key: "TG"
            },
          ],
        },
        {
          id: 4,
          name: "腎功能",
          children: [
            {
              name: "尿酸",
              key: "UA"
            },
          ],
        },
      ],
      disRR: [],
      isBuy: false,
      disPackages: [
        {
          id: "A05",
          name: '痛風',
          isBuy: false,
        },
        {
          id: "A03",
          name: '心肌梗塞',
          isBuy: false,

        },
        {
          id: 'A17',
          name: '腦中風',
          isBuy: false,

        },
        {
          id: "A07",
          name: '癌症',
          isBuy: false,
        },
      ],
      disToBuy: ""
    }
  },
  created () {
    const id = this.$route.params.id
    if (id) {
      this.getResult(id)
    } else {
      this.$router.push('/myLink')
    }
  },
  methods: {
    async getResult (id) {
      const result = await detailMyLink({ id })
      if (result.code === 200) {
        const data = JSON.parse(Base64.decode(result.data))
        this.userData = data
        this.disPackages.forEach(item => {
          const d = data.disRR.find((cItem => cItem.dis === item.id))
          if (d) {
            item.isBuy = true;
            item.rate = d.rate
          }
        })
      }
    },
    getExamine (examine, key) {
      const arr = examine.filter((i) => i.item === key)
      if (arr.length > 0) {
        return arr[0]
      }

      return ''
    },


    buyExamine (dis) {

      if (dis.isBuy) {
        this.$router.push(`/myLink/report/${dis.id}/${dis.rate}`)
      } else {
        this.isBuy = true;
        this.disToBuy = dis.id;
      }
    },
    confirmBuy () {
      this.isBuy = false;
      this.$router.push({
        path: `/myLink/pay/${this.$route.params.id}`,
        query: {
          dis: this.disPackages.filter(item => item.isBuy).map(item => item.id).join(','),
          buy: this.disToBuy,
          type: 'add'
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.font-color-red {
  color: #fe3115;
  font-weight: 500;
}

.font-color-green {
  color: #04B959;
  font-weight: 500;
}

.user-phone {
  height: 30px;
}

.content {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 0.28rem;
  letter-spacing: 0;

  .van-nav-bar-title {
    flex: 0 0 auto;
  }

  .result-content {
    width: 100%;
    flex: 1;
    overflow: auto;
    font-size: 0.28rem;
    padding: .24rem;
    background-color: #F5F8FC;

    >div {
      position: relative;
      width: 100%;
      background-color: #fff;



      .info-title {
        font-size: 0.36rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding-right: 0.12rem;

        &::before {
          content: '';
          width: 0.24rem;
          height: 0.2rem;
          border-top-right-radius: 0.2rem;
          border-bottom-right-radius: 0.2rem;
          background-color: #5272ff;
          margin-right: 0.2rem;
        }
      }
    }

    .user-info {
      position: relative;
      margin-bottom: .24rem;

      >div {
        width: 100%;
        position: relative;
      }

      .user-info-header {
        display: flex;
        align-items: center;
        justify-content: space-between;


        >div {
          flex: 1;
          height: .72rem;

          >div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        >div:first-child {
          background-color: #EEEBFE;

          >div {
            border-top-right-radius: .36rem;
            background-color: #fff;

          }
        }

        >div:last-child {
          background-color: #fff;

          >div {
            color: #634BFF;
            border-bottom-left-radius: .36rem;
            background-color: #EEEBFE;

          }
        }
      }

      .user-info-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1.4rem);
        grid-column-gap: 0.18rem;
        grid-row-gap: 0.32rem;
        padding: 0.24rem;
        grid-template-areas: "A A A" "B C D";

        >div {
          background-color: #EEEBFE;
          border-radius: .12rem;
        }

        .user-info-age-sex-height {
          grid-area: A;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(1, 1fr);
          grid-column-gap: 0.18rem;

          >div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }

        .user-info-weight {
          grid-area: B;

          .basic-info-label {
            &::before {
              background-image: url(../../assets/images/myLink/result/weight.png);
            }
          }
        }

        .user-info-waist {
          grid-area: C;

          .basic-info-label {
            &::before {
              background-image: url(../../assets/images/myLink/result/waist.png);
            }
          }
        }

        .user-info-hip {
          grid-area: D;

          .basic-info-label {
            &::before {
              background-image: url(../../assets/images/myLink/result/hip.png);
            }
          }
        }

        .user-info-weight,
        .user-info-waist,
        .user-info-hip {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: .2rem;

          .basic-info-label {
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;

            &::before {
              content: "";
              width: .36rem;
              height: .36rem;
              display: block;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: .12rem;
            }
          }
        }

        .basic-info-label {
          font-size: 0.28rem;
          color: #666666;
          line-height: 0.28rem;
          text-align: center;
          font-style: normal;
        }

        .basic-info-value {
          font-size: 0.36rem;
          color: #222222;
          line-height: 0.5rem;
          text-align: left;
          font-style: normal;
          margin-top: .08rem;

          .unit {
            font-size: .24rem;
          }
        }

      }
    }


    .user-exam-info {

      padding: .24rem 0;
      margin-bottom: .24rem;

      .info-detail {
        position: relative;
        width: 100%;
        padding: 0.24rem;

        .detail-table-header {
          background-color: #8097fd;
          height: 0.98rem;
          color: #fff;
        }

        .detail-table-td {
          height: 0.72rem;
          color: #3b4549;
        }

        .detail-table-even {
          background-color: #eff2ff;
        }

        .detail-table-tr {
          display: flex;
          align-items: center;
          width: 100%;
          border-radius: 0.16rem;

          >div {
            flex: 3;
            padding: 0.06rem;

            &:nth-child(-n + 2) {
              flex: 4;
              padding-left: 0.12rem;
            }
          }
        }

        .detail-table-type {
          position: relative;
          width: 100%;
          height: 0.76rem;
          font-weight: bold;
          line-height: 0.76rem;
          padding-left: 0.12rem;
        }
      }
    }

    .user-disease-info {
      padding: .24rem 0;

      .user-disease-content {
        display: grid;
        padding: .24rem;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 0.88rem);
        grid-row-gap: .18rem;

        >div {
          border-radius: .12rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 .24rem;
        }

        .disease-item-label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 0.28rem;

          &::before {
            content: "";
            display: block;
            width: .36rem;
            height: .36rem;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: .12rem;
          }
        }
      }

      .item-lock {
        background-color: #F2F4FA;
        color: #32363E;

        .disease-item-label {
          &::before {
            background-image: url(../../assets//images/myLink/result/disease-lock.png);
          }
        }
      }

      .item-unlock {
        background-color: #DCE3FF;
        color: #6681FA;

        .disease-item-label {
          &::before {
            background-image: url(../../assets//images/myLink/result/disease-unlock.png);
          }
        }
      }
    }


    .detail-bio-down,
    .detail-bio-up {
      position: relative;
      width: fit-content;
      display: flex;
      align-items: center;

      &::after {
        content: "";
        display: block;
        margin-left: .06rem;
        width: 0.18rem;
        height: .26rem;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
      }

      &::before {
        content: "*";
        position: absolute;
        right: calc(100% + 0.06rem);
        top: 0;
      }
    }

    .detail-bio-down {
      &::after {
        background-image: url(../../assets/images/report/bio-arrow-down.png);
      }
    }

    .detail-bio-up {



      &::after {

        background-image: url(../../assets/images/report/bio-arrow-up.png);

      }
    }


    .detail-bio-good {
      position: relative;
      width: fit-content;
      display: flex;
      align-items: center;

      &::after {
        content: "";
        display: block;
        margin-left: .06rem;
        width: 0.42rem;
        height: .38rem;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../../assets/images/report/bio-good.png);

      }
    }
  }


  .dialog-confirm {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    .dialog-confirm-content {
      position: relative;
      width: 90%;
      background-color: #fff;
      border-radius: .24rem;

      >div {
        position: relative;
        width: 100%;
      }

      .dialog-confirm-close {
        position: absolute;
        width: .54rem;
        height: .54rem;
        right: 0;
        top: -1rem;
      }

      .dialog-confirm-header {
        border-top-left-radius: .24rem;
        border-top-right-radius: .24rem;
        overflow: hidden;
        height: 1.24rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../../assets/images/myLink/result/dialog-header-bg.png);
        font-weight: 500;
        font-size: 0.32rem;
        color: #333333;
        line-height: 0.44rem;
        text-align: left;
        font-style: normal;
        display: flex;
        align-items: center;
        padding: 0 0.4rem;
      }

      .dialog-confirm-body {
        padding: .32rem;
        text-align: center;
      }

      .dialog-confirm-footer {
        background-color: #6882FB16;
        padding: 0.4rem;

        .dialog-confirm-btn {
          width: 100%;
          height: 0.68rem;
          background: #6882FB;
          border-radius: 0.34rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
      }
    }
  }
}

.simple-background {
  background-size: 100%;
}
</style>
